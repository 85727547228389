<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Contractor Export</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-name" label="Contractor Name:">
                                    <b-input-group>
                                        <b-input id="search-contractor-name" size="sm" placeholder="Contractor Name" v-model="contractorNameFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="contractorNameFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-contact-name" label="Contact Name:">
                                    <b-input-group>
                                        <b-input id="search-contact-name" size="sm" placeholder="Contact Name" v-model="contactNameFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="contactNameFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-type" label="Type:">
                                    <b-input-group>
                                        <b-select id="search-type" size="sm" :options="contractorTypeOptions" v-model="typeFilter">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="typeFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-city" label="Contractor City:">
                                    <b-input-group>
                                        <b-input id="search-city" size="sm" placeholder="Contractor City" v-model="cityFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="cityFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-state" label="Contractor State:">
                                    <b-input-group>
                                        <state-select :id="'search-state'" size="sm" v-model="stateFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="stateFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-status" label="Status:">
                                    <b-input-group>
                                        <b-select id="search-status" size="sm" v-model="statusFilter">
                                            <option :value="null"> - </option>
                                            <option :value="true">Participating</option>
                                            <option :value="false">Not Participating</option>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="statusFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store/store';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import StateSelect from '@/components/shared/StateSelect.vue';
import _ from 'underscore';
import { errorModalOptions, errorToastOptions, trimToNull } from '@/util/formatters';
import { NULL_ORGANIZATION, OrganizationContact } from '@/model/organization';
import download from '@/util/downloader';

@Component({
    components: {
        StateSelect
    }
})
export default class ContractorExport extends Vue {

    contractorNameFilter = null;
    contactNameFilter = null;
    typeFilter = null;
    cityFilter = null;
    stateFilter = null;
    statusFilter = null;


    get contractorTypeOptions() {
        const types = this.$store.getters['organizations/getContractorTypes'];

        return _.map(types, (t) => {
            return {
                value: t,
                text: t.type
            };
        });
    }

    clearFilters() {
        this.contractorNameFilter = null;
        this.contactNameFilter = null;
        this.typeFilter = null;
        this.cityFilter = null;
        this.stateFilter = null;
        this.statusFilter = null;
    }

    async getCsv() {
        //Insert criteria into Organization model
        const criteria = NULL_ORGANIZATION.clone();
        criteria.name = this.contractorNameFilter;
        criteria.type = this.typeFilter;
        criteria.city = this.cityFilter;
        criteria.state = this.stateFilter;
        criteria.participating = this.statusFilter;
        //Serialize contact name
        const contactName = trimToNull(this.contactNameFilter);
        if (null !== contactName) {
            const names = contactName.split(/\s+/);
            const fname = names[0];
            const lname = 1 < names.length ? trimToNull(names[1]) : null;
            const contractorContact = OrganizationContact.create({
                firstName: fname,
                lastName: lname
            });
            criteria.contacts = [contractorContact];
        }
        // console.log(criteria);
        try {
            download('POST', '/api/v1/export/contractors', JSON.stringify(criteria.cloneTemplate()));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async beforeRouteEnter(to, from, next) {
        const deps = [
            'organizations/loadContractorTypes',
            'common/loadStates'
        ];
        _.each(deps, (action) => {
            store.dispatch(action)
                .catch(error => {
                    const vm = new Vue();
                    vm.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        next();
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Contractor Export', null, true));
    }

}
</script>


<style scoped>

</style>
